<template>
  <div class="reg">
    <div class="reg-header">
      <topNav></topNav>
    </div>
    <div class="reg-main">
      <div class="m-steps">
        <Steps :current="curIndex">
          <Step title="验证手机"></Step>
          <Step title="基本信息"></Step>
          <Step title="完成注册"></Step>
        </Steps>
      </div>

      <div class="admin-add">
        <div class="admin-add-content">
          <Form class="F" ref="formInline" :model="formInline" :rules="ruleInline" @keyup.enter.native="handleSubmit('formInline')">
            <FormItem class="F-1" prop="phone">
              <Input class="I" type="text" v-model="formInline.phone" placeholder="请输入手机号" />
            </FormItem>
            <FormItem class="F-1" prop="captcha">
              <Input class="I" ref="captcha_input" maxlength="6" v-model="formInline.captcha" placeholder="请输入图片验证码" />
              <img class="code" :src="'data:image/png;base64,' + captchaImg.data" alt="验证码" v-if="captchaImg && captchaImg.data" @click="getCode" />
            </FormItem>
            <FormItem class="F-1 phone_code" prop="code">
              <Input class="I" type="text" ref="captcha_input2" maxlength="6" v-model="formInline.code" placeholder="请输入短信验证码" />
              <Button v-if="showCode" @click="getPhoneCode">获取验证码</Button>
              <Button v-else disabled style="color: #ccc">{{ count }}s后重新获取</Button>
            </FormItem>
            <Button type="primary" @click="handleSubmit('formInline')" size="large" class="btn">下一步</Button>
          </Form>
        </div>
      </div>

      <iModal v-model="showModel" :mask-closable="false" @on-cancel="cancel" :styles="{ top: '200px' }" :footer-hide="true">
        <div class="model">
          <svg class="icon-svg icon-big" aria-hidden="true">
            <use xlink:href="#icon-j"></use>
          </svg>
          <p>
            {{ this.formInline.phone }}已被以下账号占用
            <br />
            请确认是否为您所有
          </p>
          <div class="user-info">
            <img :src="userMsg.avatarUrl" v-if="userMsg.avatarUrl" />
            <img src="@/assets/img/empty.png" v-else />
            <span>{{ userMsg.nickName || userMsg.name }}</span>
          </div>
          <div class="bot-btn">
            <Button type="primary" @click="linkTo('no')">不是我的，继续注册</Button>
            <Button type="primary" @click="linkTo('yes')">是我的，立即登录</Button>
          </div>
        </div>
      </iModal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validator } from "@/other/validator";
import topNav from "./components/top";

export default {
  name: "reg",
  data () {
    return {
      curIndex: 0,
      formInline: {
        phone: "",
        code: "",
        captcha: "",
      },
      ruleInline: {
        captcha: [{ required: true, message: "图片不能为空", trigger: "blur" }],
        phone: validator.phone,
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
      },
      showCode: true,
      count: "",
      codeKey: "",
      captchaImg: "",
      userMsg: "",
      showModel: false,
    };
  },
  components: {
    topNav,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
    }),
  },
  async created () {
    this.getCode();
  },
  methods: {
    ...mapActions({
      getImgCode: "loginModule/getImgCode",
      validateImgCode: "loginModule/validateImgCode",
      getMsgCode: "getMsgCode",
      validateCode: "validateCode",
      graphqlPost: "graphqlPostByZXS",
      linkToLogin: "linkTo/linkToLogin",
      getCustomForm: "getCustomForm",
      linkToRegForm: "linkTo/linkToRegForm",
    }),
    cancel () {
      this.$refs["formInline"].resetFields();
      this.codeKey = "";
      this.formInline.jobTitle = "";
      this.formInline.name = "";
      this.formInline.tel = "";
    },
    async getCaptcha () {
      this.$refs.formInline.validateField("tel", (val) => {
        if (val !== "") return;
        this.downTime();
      });
    },
    async getPhoneCode () {
      this.$refs.formInline.validateField("phone", (val) => {
        if (val !== "") return;
        this.formInline.code = "";
        this.$refs.captcha_input2.focus();
        this.downTime();
      });
    },
    async getCode () {
      // 获取code
      this.captchaImg = await this.getImgCode();
    },
    async downTime () {
      const valid = await this.getMsgCode(this.formInline.phone);
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async validMsgCode () {
      // 验证code
      if (!this.formInline.code) return false;
      if (!this.codeKey) {
        this.$Message.warning("请获取验证码!");
        return false;
      }
      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.formInline.code,
      });
      if (!valid) {
        this.$Message.error("验证码未通过!");
        return false;
      }
      return true;
    },
    async validCode () {
      // 验证图片验证码
      if (!this.captchaImg || !this.formInline.captcha) return false;
      let valid = await this.validateImgCode({
        Key: this.captchaImg.key,
        Code: this.formInline.captcha,
      });
      if (!valid) {
        this.$Message.error("图片验证码错误，请重试！");
        this.formInline.captcha = "";
        this.$refs.captcha_input.focus();
        this.getCode();
        return false;
      }
      return true;
    },
    validForm (name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async handleSubmit (name) {
      let valid = await this.validForm(name);
      if (!valid) return;
      let captchaValid = await this.validCode();
      if (!captchaValid) return;
      let codeValid = await this.validMsgCode(); 
      if (!codeValid) return;
      this.getMember();
    },

    async getMember () {
      let query = `
        query($phoneNumber: String!){
          memberQuery{
            getByPhoneNumber(phoneNumber:$phoneNumber){
                avatarUrl
                name
                nickName
                phone
              }
            }
        }
      `;
      let opt = {
        query: query,
        variables: {
          phoneNumber: this.formInline.phone,
        },
      };
      let data = await this.graphqlPost(opt);
      if (data.data.memberQuery.getByPhoneNumber) {
        this.userMsg = data.data.memberQuery.getByPhoneNumber;
        this.showModel = true;
      } else {
        this.linkTo("next");
      }
    },
    linkTo (type) {
      switch (type) {
        case "yes": // 是我的
          this.linkToLogin();
          break;
        case "no": // 不是我的
          let opt1 = {
            phone: this.formInline.phone,
            type: "no",
          };
          this.linkToRegForm(opt1);
          break;
        case "next": // 不是我的
          let opt2 = {
            phone: this.formInline.phone,
            type: "next",
          };
          this.linkToRegForm(opt2);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.reg {
  &-header {
    box-shadow: 0px 2px 3px 0px rgba(101, 101, 101, 0.1);
  }
  &-main {
    width: 1200px;
    margin: 50px auto;
    ::v-deep .m-steps {
      width: 710px;
      margin: 0 auto;
      .ivu-steps-item {
        .ivu-steps-head {
          background: transparent;
        }
      }
    }
  }
  .F {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 380px;
    position: relative;
    margin: 0 auto;
    &-1 {
      margin-top: 34px;
      margin-bottom: 0;
      &.phone_code {
        ::v-deep {
          .ivu-form-item-content {
            display: flex;
            .ivu-btn {
              margin-left: 10px;
            }
          }
        }
      }
      .I {
        // margin-top: 19px;
      }
    }
    .F-2 {
      width: 100%;
      height: 15px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      .F-2-text {
        width: 48px;
        height: 13px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
    }
    .wx-btn {
      margin-top: 20px;
    }
    .code {
      width: 70px;
      height: 26px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      cursor: pointer;
    }
    .btn {
      margin-top: 34px;
    }
  }
}
.model {
  width: 340px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .icon-big {
    font-size: 100px;
    text-align: center;
    margin: 0 auto;
  }
  p {
    font-size: 16px;
    text-align: center;
    padding: 18px 0 34px;
    line-height: 2;
  }
  .user-info {
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    img {
      width: 64px;
      height: 64px;
      display: block;
      margin-right: 14px;
      border-radius: 50%;
    }
    span {
      font-size: 16px;
    }
  }
  .bot-btn {
    display: flex;
    justify-content: space-between;
  }
}
</style>
